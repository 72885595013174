window.BETCLOUD_ENV = window.BETCLOUD_ENV || process.env;

/**
 * This is where we need to be for production readiness
 */
export enum EThemes {
  Base,
  Wellbet,
  Betgalaxy,
  Questbet,
  Betroyale,
  Volcanobet,
  Fiestabet,
  Puntcity,
  Puntgenie,
  Betprofessor,
  Sterlingparker,
  Vikingbet,
  Goldenrush,
  Juicybet,
  Forsport,
  Junglebet,
  Sugarcastle,
  Oldgill,
  Buffalobet,
  Titanbet,
  Slambet,
  Templebet,
  GoldenBet888,
  Gigabet,
  Luckystride,
  Chromabet,
  Betbetbet,
  Hyperbet,
  Bet777,
  Betblast,
  Bet575,
}

/**
 * This is where we currently are
 */
export enum EThemesOG {
  Wellbet = 'wellbet',
  Betgalaxy = 'betgalaxy',
  Questbet = 'questbet',
  Betroyale = 'betroyale',
  Volcanobet = 'volcanobet',
  Fiestabet = 'fiestabet',
  Puntcity = 'puntcity',
  Puntgenie = 'puntgenie',
  Betprofessor = 'betprofessor',
  Sterlingparker = 'sterlingparker',
  Base = 'base',
  Vikingbet = 'vikingbet',
  Goldenrush = 'goldenrush',
  Juicybet = 'juicybet',
  Forsport = 'forsport',
  Junglebet = 'junglebet',
  Sugarcastle = 'sugarcastle',
  Oldgill = 'oldgill',
  Buffalobet = 'buffalobet',
  Titanbet = 'titanbet',
  Slambet = 'slambet',
  Templebet = 'templebet',
  GoldenBet888 = 'goldenbet888',
  Gigabet = 'gigabet',
  Luckystride = 'luckystride',
  Chromabet = 'chromabet',
  Betbetbet = 'betbetbet',
  Hyperbet = 'hyperbet',
  Bet777 = 'bet777',
  Betblast = 'betblast',
  Bet575 = 'bet575',
}

export const THEME_NAME: Record<EThemesOG, string> = {
  [EThemesOG.Wellbet]: 'WellBet',
  [EThemesOG.Betgalaxy]: 'BetGalaxy',
  [EThemesOG.Questbet]: 'QuestBet',
  [EThemesOG.Betroyale]: 'BetRoyale',
  [EThemesOG.Volcanobet]: 'VolcanoBet',
  [EThemesOG.Fiestabet]: 'FiestaBet',
  [EThemesOG.Puntcity]: 'PuntCity',
  [EThemesOG.Puntgenie]: 'PuntGenie',
  [EThemesOG.Betprofessor]: 'BetProfessor',
  [EThemesOG.Sterlingparker]: 'SterlingParker',
  [EThemesOG.Base]: 'Base',
  [EThemesOG.Vikingbet]: 'Vikingbet',
  [EThemesOG.Goldenrush]: 'Goldenrush',
  [EThemesOG.Juicybet]: 'Juicybet',
  [EThemesOG.Forsport]: 'Forsport',
  [EThemesOG.Junglebet]: 'Junglebet',
  [EThemesOG.Sugarcastle]: 'Sugarcastle',
  [EThemesOG.Oldgill]: 'Oldgill',
  [EThemesOG.Buffalobet]: 'Buffalobet',
  [EThemesOG.Titanbet]: 'Titanbet',
  [EThemesOG.Slambet]: 'Slambet',
  [EThemesOG.Templebet]: 'Templebet',
  [EThemesOG.GoldenBet888]: 'GoldenBet888',
  [EThemesOG.Gigabet]: 'Gigabet',
  [EThemesOG.Luckystride]: 'Luckystride',
  [EThemesOG.Chromabet]: 'Chromabet',
  [EThemesOG.Betbetbet]: 'Betbetbet',
  [EThemesOG.Hyperbet]: 'Hyperbet',
  [EThemesOG.Bet777]: 'Bet777',
  [EThemesOG.Betblast]: 'Betblast',
  [EThemesOG.Bet575]: 'Bet575',
};

export type TThemeConfig = {
  name: string;
  legalName: string;
  phone: string;
  address: string;
  email: string;
  abn?: string;
  acn?: string;
  closeAccNumber?: string;
};

export const THEME_CONFIG: Record<EThemesOG, TThemeConfig> = {
  [EThemesOG.Wellbet]: {
    name: THEME_NAME[EThemesOG.Wellbet],
    legalName: 'Wellbet Pty Ltd',
    phone: '',
    address: 'Level 4, 150 Albert Road, South Melbourne VIC 3205',
    email: 'service@wellbet.com.au',
    acn: '658 541 194',
    closeAccNumber: '(03) 7044 0636',
  },
  [EThemesOG.Betgalaxy]: {
    name: THEME_NAME[EThemesOG.Betgalaxy],
    legalName: 'Track Pursuits Pty Ltd',
    phone: '',
    address: '17 Valentine Ave Kew VIC 3101',
    email: 'service@betgalaxy.com.au',
    acn: '661 549 819',
    closeAccNumber: '(03) 7042 2798',
  },
  [EThemesOG.Questbet]: {
    name: THEME_NAME[EThemesOG.Questbet],
    legalName: 'QuestBet Pty Ltd',
    phone: '0433339347',
    address: '71 Stanley Street Black Rock 3193 VIC',
    email: 'service@questbet.com.au',
    acn: '662473047',
    closeAccNumber: '(03) 9001 3215',
  },
  [EThemesOG.Betroyale]: {
    name: THEME_NAME[EThemesOG.Betroyale],
    legalName: 'Betroyale Pty Ltd',
    phone: '0411825533',
    address: '124 Leopold St, South Yarra, VIC, 3141',
    email: 'service@betroyale.com.au',
    acn: '662 459 814',
    closeAccNumber: '(03) 7043 1222',
  },
  [EThemesOG.Volcanobet]: {
    name: THEME_NAME[EThemesOG.Volcanobet],
    legalName: 'Volcano Pty Ltd',
    phone: '0427788097',
    address: 'Level 14, 350 Collins Street, Melbourne VIC 3000',
    email: 'service@volcanobet.com.au',
    acn: '666 349 293',
    closeAccNumber: '(03) 9020 3622',
  },
  [EThemesOG.Fiestabet]: {
    name: THEME_NAME[EThemesOG.Fiestabet],
    legalName: 'FiestaBet Pty Ltd',
    phone: '0413609309',
    address: 'Suite 35/1140 Nepean Highway, Mornington, VIC, 3931',
    email: 'service@fiestabet.com.au',
    acn: '666 506 181',
    closeAccNumber: '(03) 7035 5731',
  },
  [EThemesOG.Puntcity]: {
    name: THEME_NAME[EThemesOG.Puntcity],
    legalName: 'Pcity Pty Ltd (PuntCity)',
    phone: '0459 500 472',
    address: '3 Tilba St, Narooma, NSW, 2546',
    email: 'service@puntcity.com.au',
    acn: '665 371 808',
    closeAccNumber: '(08) 7081 9558',
  },
  [EThemesOG.Puntgenie]: {
    name: THEME_NAME[EThemesOG.Puntgenie],
    legalName: 'PuntGenie',
    phone: '0414 404 000',
    address: '189 Bambra Road, Caulfield South VIC 3162',
    email: 'service@puntgenie.com.au',
    acn: '',
    abn: '90 936 613 803',
    closeAccNumber: '(03) 8657 3127',
  },
  [EThemesOG.Base]: {
    name: THEME_NAME[EThemesOG.Wellbet],
    legalName: 'Wellbet Pty Ltd',
    phone: '',
    address: 'Level 4, 150 Albert Road, South Melbourne VIC 3205',
    email: 'service@wellbet.com.au',
    acn: '658 541 194',
  },
  [EThemesOG.Betprofessor]: {
    name: THEME_NAME[EThemesOG.Betprofessor],
    legalName: 'GiantBet Pty Ltd T/A BetProfessor',
    phone: '0418552318',
    address: 'Level 4-150 Albert Road, South Melbourne. VIC 3205',
    email: 'service@betprofessor.com.au',
    acn: '667 202 806',
    closeAccNumber: '(03) 9020 4998',
  },
  [EThemesOG.Sterlingparker]: {
    name: THEME_NAME[EThemesOG.Sterlingparker],
    legalName: 'G&N Engellener T/A Sterling Parker',
    phone: '0411613440',
    address: 'Suite 137/425 Smith St Fitzroy VIC 3065',
    email: 'service@sterlingparker.com.au',
    acn: '',
    abn: '43298200584',
    closeAccNumber: '(03) 7043 0241',
  },
  [EThemesOG.Vikingbet]: {
    name: THEME_NAME[EThemesOG.Vikingbet],
    legalName: 'Viking Gaming Pty Ltd',
    phone: '(08) 7078 5820',
    address: 'Level 14, 350 Collins Street, Melbourne VIC 3000',
    email: 'service@vikingbet.com.au',
    acn: '665 768 749',
    closeAccNumber: '(08) 7078 5820',
  },
  [EThemesOG.Goldenrush]: {
    name: THEME_NAME[EThemesOG.Goldenrush],
    legalName: '',
    phone: '',
    address: '',
    email: 'service@goldenrush.com.au',
    acn: '',
    closeAccNumber: '(03) 7043 4201',
  },
  [EThemesOG.Juicybet]: {
    name: THEME_NAME[EThemesOG.Juicybet],
    legalName: 'JuicyBet Pty Ltd',
    phone: '0409 890 179',
    address: '65 Royal Road Bonbeach 3195 VIC',
    email: 'service@juicybet.com.au',
    acn: '668 408 400',
    closeAccNumber: '(03) 7043 2991',
  },
  [EThemesOG.Forsport]: {
    name: THEME_NAME[EThemesOG.Forsport],
    legalName: '',
    phone: '',
    address: '',
    email: '',
    acn: '',
  },
  [EThemesOG.Junglebet]: {
    name: THEME_NAME[EThemesOG.Junglebet],
    legalName: 'Jungle Strategic Pty Ltd',
    phone: '0459 500 472',
    address: '3 Tilba St, Narooma, NSW, 2546',
    email: 'service@junglebet.com.au',
    acn: '663 374 132',
    abn: '37 663 374 132',
    closeAccNumber: '(03) 7042 4815',
  },
  [EThemesOG.Sugarcastle]: {
    name: THEME_NAME[EThemesOG.Sugarcastle],
    legalName: 'Lincoln Hsu',
    phone: '',
    address: 'Level 14, 350 Collins Street, Melbourne VIC 3000',
    email: 'service@sugarcastle.com.au',
    acn: '60158632601',
    closeAccNumber: '(03) 7043 2712',
  },
  [EThemesOG.Oldgill]: {
    name: THEME_NAME[EThemesOG.Oldgill],
    legalName: 'Greg Engellener T/A Old Gill',
    phone: '0411613440',
    address: '7 Hill Mews, Eltham, VIC, 3095',
    email: 'service@oldgill.com.au',
    abn: '316 08 137 440',
    closeAccNumber: '(03) 7043 4223',
  },
  [EThemesOG.Buffalobet]: {
    name: THEME_NAME[EThemesOG.Buffalobet],
    legalName: 'Damien McGrath T/A Buffalo Bet',
    phone: '0400150424',
    address: '75 Marriage Rd, Brighton East, VIC, 3187',
    email: 'service@buffalobet.com.au',
    acn: '33476057579',
    closeAccNumber: '(03) 7043 5349',
  },
  [EThemesOG.Titanbet]: {
    name: THEME_NAME[EThemesOG.Titanbet],
    legalName: 'TITANBET PTY LTD',
    phone: '',
    address: '71 Stanley Street, BLACK ROCK, VIC 3193',
    email: 'service@titanbet.com.au',
    acn: '668 445 216',
    closeAccNumber: '(03) 7043 3469',
  },
  [EThemesOG.Slambet]: {
    name: THEME_NAME[EThemesOG.Slambet],
    legalName: 'Viking Gaming',
    phone: '+61450892167',
    address:
      'C/o Senet Legal Pty Ltd, Level 14,350 Collins Street, Melbourne VIC 3000',
    email: 'service@slambet.com.au',
    acn: '665 768 749',
    abn: '62 665 768 749',
    closeAccNumber: '(08) 7083 3008',
  },
  [EThemesOG.Templebet]: {
    name: THEME_NAME[EThemesOG.Templebet],
    legalName: '',
    phone: '',
    address: '',
    email: 'service@templebet.com.au',
    acn: '',
    closeAccNumber: '(03) 7042 6113',
  },

  [EThemesOG.GoldenBet888]: {
    name: THEME_NAME[EThemesOG.GoldenBet888],
    legalName: 'GoldenBet888 Pty Ltd',
    phone: '+61402160868',
    address: 'Level 3, 174 Queen Street Melbourne VIC 3000',
    email: 'service@goldenbet888.com.au',
    acn: '674922788',
    closeAccNumber: '(08) 7083 3409',
  },
  [EThemesOG.Gigabet]: {
    name: THEME_NAME[EThemesOG.Gigabet],
    legalName: 'Viking Gaming',
    phone: '+61450892167',
    address:
      'C/o Senet Legal Pty Ltd, Level 14,350 Collins Street, Melbourne VIC 3000',
    email: 'service@gigabet.com.au',
    acn: '665 768 749',
    abn: '62 665 768 749',
    closeAccNumber: '(08) 7093 6291',
  },
  [EThemesOG.Luckystride]: {
    name: THEME_NAME[EThemesOG.Luckystride],
    legalName: '',
    phone: '',
    address: '',
    email: 'service@betstride.com.au',
    acn: '',
  },
  [EThemesOG.Chromabet]: {
    name: THEME_NAME[EThemesOG.Chromabet],
    legalName: 'G MANSOUR & T.L MANSOUR T/A ChromaBet',
    phone: '',
    address: '26 Cantala Dr Doncaster VIC 3108 Australia',
    email: 'help@chromabet.com.au',
    acn: '51454136819',
    closeAccNumber: '(03) 7076 2510',
  },
  [EThemesOG.Betbetbet]: {
    name: THEME_NAME[EThemesOG.Betbetbet],
    legalName: '',
    phone: '',
    address: '',
    email: '',
    acn: '',
  },
  [EThemesOG.Hyperbet]: {
    name: THEME_NAME[EThemesOG.Hyperbet],
    legalName: '',
    phone: '',
    address: '',
    email: '',
    acn: '',
  },
  [EThemesOG.Bet777]: {
    name: THEME_NAME[EThemesOG.Bet777],
    legalName: 'Gary Fitzpatrick T/A Bet777',
    phone: '(03) 7043 4068',
    address: '11 Bolton Avenue Hampton VIC 3188 Australia',
    email: 'service@bet777.com.au',
    acn: '74602395834',
    closeAccNumber: '(03) 7043 4068',
  },
  [EThemesOG.Betblast]: {
    name: THEME_NAME[EThemesOG.Betblast],
    legalName: '',
    phone: '',
    address: '',
    email: 'service@betblast.com.au',
    acn: '',
    closeAccNumber: '',
  },
  [EThemesOG.Bet575]: {
    name: THEME_NAME[EThemesOG.Bet575],
    legalName: '',
    phone: '',
    address: '',
    email: 'service@bet575.com.au',
    acn: '',
  },
};
